/**
 *
 * Medical Info Categories Javascript file
 * * endpoint /medical-info-categories
 *
 * */

var medicalInfoCat = {

    //Data
    data: {
        list: [] //Topic list data use to render html in table list
    },

    /**
     * @return {string}
     */
    tableRowHTML: function (data) {
        var _this = this;
        var changed = data.display_order !== data.index + 1;
        return '' +
            '<tr class="' + (changed ? 'checked' : '') + '">\n' +
            '    <td>' + data.display_order + (changed ? ' &#8680; ' + (data.index + 1) : '') + '</td>\n' +
            '    <td>' + data.slug + '</td>\n' +
            '    <td>' + data.translations.th.name + '</td>\n' +
            '    <td>' + data.translations.en.name + '</td>\n' +
            '    <td>\n' +
            '        <button type="button" class="btn btn-info btn-xs" ' +
            '            onclick="medicalInfoCat.orderCategory(' + data.index + ', 1);" ' +
            '        ' + ((data.index === 0) ? 'style="visibility: hidden"' : '') + '>\n' +
            '           &#8679\n' +
            '        </button>\n' +
            '        <button type="button" class="btn btn-info btn-xs" ' +
            '            onclick="medicalInfoCat.orderCategory(' + data.index + ', -1);" ' +
            '        ' + ((data.index === _this.data.list.length - 1) ? 'style="visibility: hidden"' : '') + '>\n' +
            '           &#8681;\n' +
            '        </button>\n' +
            '        <button type="button" class="btn btn-warning btn-xs" onclick="medicalInfoCat.openEdit(' + data.id + ');">\n' +
            '            Edit\n' +
            '        </button>\n' +
            '        <button type="button" class="btn btn-danger btn-xs" onclick="medicalInfoCat.confirmDelete(' + data.id + ');">\n' +
            '            Delete\n' +
            '        </button>\n' +
            '    </td>\n' +
            '</tr>'
    },

    init: function () {

        this.endpoint = API3_URL + 'admin/medical-info-categories';

        //Define Main element with jquery selector
        this.popupModal = $('#edit-medicals-modal');
        this.editForm = $('#edit-medical-form');
        this.tableTarget = $('#medical-foreach-list');

        //First load product list form api
        this.loadListData();
    },

    /** Load data form api */
    loadListData: function () {
        var _this = this;

        loadingScreen.show();

        $.ajax({
            url: this.endpoint,
            type: 'get',
            success: function (data) {
                _this.data.list = data; //load data to this data list
                _this.renderListHTML(); //update html to show list
            },
            error: function () {
                console.log('error');
            },
            complete: function () {
                loadingScreen.fadeOut('slow');
            }
        });
    },

    getItem: function (id) {
        var _item = null;
        this.data.list.forEach(function (data) {
            if (data.id === id)
                _item = data;
        });
        return _item;
    },

    //Append table row to html
    renderListHTML: function () {
        //Clear html
        this.tableTarget.html('');

        //If data list have some data
        if (this.data.list.length) {
            var _this = this;
            var _index = 0;

            //Foreach data list, Append data to HTML
            this.data.list.forEach(function (data) {
                data.index = _index++;
                _this.tableTarget.append(_this.tableRowHTML(data));
            });

        } else {
            //No data html
            this.tableTarget.append('<tr><td colspan="5" style="text-align: center">ไม่มีข้อมูล</td></tr>');
        }

    },

    /** set order up/down by one  */
    orderCategory: function (index, updown) {
        this.data.list.splice(index - updown, 0, this.data.list.splice(index, 1)[0]);
        this.renderListHTML();
    },

    saveOrdered: function () {
        var ordered = [];

        this.data.list.forEach(function (data) {
            ordered.push(data.id);
        });

        loadingScreen.show();

        var _this = this;
        $.ajax({
            url: this.endpoint,
            type: 'put',
            data: {ordered: ordered},
            success: function (data) {
                _this.data.list = data.list; //load data to this data list

                $.notify({
                    message: 'บันทึกข้อมูลการเรียงลำดับแล้ว'
                }, {
                    type: 'success',
                    placement: {
                        from: "top",
                        align: "center"
                    },
                    offset: 50
                });

                _this.renderListHTML(); //update html to show list
            },
            error: function () {
                console.log('error');

                $.notify({
                    message: 'บันทึดข้อมูลไม่สำเร็จ'
                }, {
                    type: 'danger',
                    placement: {
                        from: "top",
                        align: "center"
                    },
                    offset: 50
                });
            },
            complete: function () {
                loadingScreen.fadeOut('slow');
            }
        });

    },

    /** Create action  */
    openCreate: function () {

        $('#loading').fadeOut();
        this.popupModal.modal('show');

        this.editForm.find('input[name=id]').val(0);
        this.editForm.find('input[name=slug]').val('');
        this.editForm.find('input[name=name_en]').val('');
        this.editForm.find('input[name=name_th]').val('');
        this.editForm.find('textarea[name=detail]').val('');

    },

    /** Edit load action  */
    openEdit: function (id) {
        var data = this.getItem(id);

        this.popupModal.modal('show');
        this.editForm.find('input[name=id]').val(data.id);
        this.editForm.find('input[name=slug]').val(data.slug);
        this.editForm.find('input[name=name_th]').val(data.translations.th.name);
        this.editForm.find('input[name=name_en]').val(data.translations.en.name);
    },

    /** Create/Edit post action */
    saveCategoryData: function () {
        event.preventDefault();

        var _this = this;
        var _id = _this.editForm.find('input[name=id]').val();
        var endpoint = this.endpoint;
        var type;

        console.log(_id);

        if (_id === '0') {
            type = 'post';
        } else {
            endpoint += '/' + _id;
            type = 'put';
        }

        loadingScreen.show();

        $.ajax({
            url: endpoint,
            type: type,
            data: _this.editForm.serialize(),
            success: function (data) {
                if (!(data.createData || data.updateStatus))
                    alert('error');

                $.notify({
                    message: 'บันทึกข้อมูลแล้ว'
                }, {
                    type: 'success',
                    placement: {
                        from: "top",
                        align: "center"
                    },
                    offset: 50
                });

                _this.loadListData();
                _this.popupModal.modal('hide');
            },
            error: function () {
                console.log('error');

                $.notify({
                    message: 'บันทึกข้อมูลไม่สำเร็จ'
                }, {
                    type: 'danger',
                    placement: {
                        from: "top",
                        align: "center"
                    },
                    offset: 50
                });
            },
            complete: function () {
                loadingScreen.fadeOut('slow');
            }
        });
    },

    /** Delete action */
    confirmDelete: function (id) {
        var _this = this;

        if (confirm('อาจมีข้อมูลที่อ้าวอิงหัวข้อนี้ ยืนยันการลบ?')) {
            loadingScreen.show();

            $.ajax({
                url: this.endpoint + '/' + id,
                type: 'delete',
                success: function (data) {
                    $.notify({
                        message: 'ลบข้อมูลแล้ว'
                    }, {
                        type: 'success',
                        placement: {
                            from: "top",
                            align: "center"
                        },
                        offset: 50
                    });

                    _this.loadListData();
                },
                error: function () {
                    console.log('error');

                    $.notify({
                        message: 'ลบข้อมูลไม่สำเร็จ'
                    }, {
                        type: 'danger',
                        placement: {
                            from: "top",
                            align: "center"
                        },
                        offset: 50
                    });
                },
                complete: function () {
                    loadingScreen.fadeOut('slow');
                }
            });
        }
    }
};
