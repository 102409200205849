
function timeTh(datex) {
    var a = new Date(datex * 1000);
    var thMonth = ["มกราคม","กุมภาพันธ์","มีนาคม","เมษายน","พฤษภาคม","มิถุนายน", "กรกฎาคม","สิงหาคม","กันยายน","ตุลาคม","พฤศจิกายน","ธันวาคม"];
    var year = a.getFullYear();
    var month = thMonth[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var minute = a.getMinutes() < 10 ? '0'+a.getMinutes() : a.getMinutes();
    var sec = a.getSeconds() < 10 ? '0'+a.getSeconds() : a.getSeconds();
    return date + ' ' + month + ' ' + (year+543) + ' ' + hour + ':' + minute + ' น.'/* + sec */;
}

function timeSince(date) {
    var seconds = Math.floor((new Date() - (date * 1000)) / 1000);
    var interval = Math.floor(seconds / 31536000);
    if (interval >= 1) {
        return " " + interval + " ปี";
    }
    interval = Math.floor(seconds / 2592000);
    if (interval >= 1) {
        return " " + interval + " เดือน";
    }
    interval = Math.floor(seconds / 86400);
    if (interval >= 1) {
        return " " + interval + " วัน";
    }
    interval = Math.floor(seconds / 3600);
    if (interval >= 1) {
        return " " + interval + " ชม.";
    }
    interval = Math.floor(seconds / 60);
    if (interval >= 1) {
        return " " + interval + " นาที";
    }
    interval = seconds;
    if (interval >= 10) {
        return " " + interval + " วินาที";
    }
    return "ซักครู่";
}