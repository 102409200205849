/**
 * Is element content empty?
 *
 * @return boolean
 */
function isEmpty( el ){
	return !$.trim(el.html())
}

/**
 * Check all required fields over the form.
 *
 * @param object form
 * @return boolean
 */
function checkRequiredFields(form) {
	var is_empty = false
	$('[required]', form).each(function(index, element) {
		if ( $(element).val() === '' ) {
			$(element).closest('.form-group').addClass('has-error has-feedback')
			$(element).after( $('<span />', { class: 'glyphicon glyphicon-remove form-control-feedback'}) )
		} else {
			$(element).closest('.form-group').removeClass('has-error has-feedback')
			$(element).closest('.form-group').find('.glyphicon.glyphicon-remove.form-control-feedback').remove()
		}
		is_empty = is_empty || ($(element).val() === '')
	})
	
	if ($('.has-error').length) {
		var id = $('.has-error').first().closest('.tab-pane').attr('id')
		$('[role=presentation] a[href=#'+id+']').click();
		$('.has-error').first().find('input, textarea, selete').focus()
		alert('กรุณากรอกข้อมูลให้ครบถ้วน');
	}

	return !is_empty
}

/**
 * Object to List Elements Convertor.
 * 
 * @param 	Object/Array response
 * @return  Object <ul>
 */
function object2List(response)
{
	if ( $.isPlainObject(response) || $.isArray(response) ) {
		var ul = $('<ul />');
		$.each(response, function(index, value) {
			ul.append( object2List(value) );
		})
		return ul
	}
		
	return $('<li />').html(response)
}

/**
 * Create Alart section in to #response-section element.
 *
 * @param 	string 	type
 * @param 	N/A 	response
 * @return 	response.
 */ 
function notify(response, type)
{
	type = typeof type !== 'undefined' ? type : 'info';

	var closeBtn = $('<button />', { class: 'close', type : 'button'}).html($('<span />').html('&times;'));
	var alert = $('<div />', { class : 'alert alert-' + type, id : 'notify-alert' }).append( closeBtn );

	alert.append( object2List(response) );

	$('#response-section').html( alert );
}