if ($.cookie('jwt')) {
    $.ajaxSetup({
        headers: {Authorization: 'Bearer ' + $.cookie('jwt')}
    });
}

function resize()
{
	$(".equal .panel").height(Math.max.apply(null, $(".equal .panel").map(function() { return $(this).height(); })));
}

$(document).ready(function () {
	// Bootstrap Scripts
	$('[data-toggle="tooltip"]').tooltip()
	$('[data-toggle="popover"]').popover({
		content: function() {
			if ($(this).data('target-content'))
				return $($(this).data('target-content')).html()
		},
		title: function() {
			if ($(this).data('target-title'))
				return $($(this).data('target-title')).html()
		}
	})
	$(document).on('click', function (e) {
		// did not click a popover toggle or popover
		if ($(e.target).data('toggle') !== 'popover' && $(e.target).parents('.popover.in').length === 0) {
			$('[data-toggle="popover"]').popover('hide')
		}
	})
    // End - Bootstrap Script.
    
    $('.image').fancybox({
	  helpers: {
	    overlay: {
	      locked: false
	    }
	  }
	});

	// Set Default Validation Script.
	$.validator.setDefaults({
		errorElement : "span",
		errorClass : "help-block",
		validClass : 'has-success',
		highlight : function (element, errorClass, validClass) {
			if (element.type == 'checkbox' || element.type == 'radio') {
				this.findByName(element.name).addClass(errorClass).removeClass(validClass);
			} else {
				$(element).closest('.form-group')
					.removeClass('has-success has-feedback')
					.addClass('has-error has-feedback');
				$(element).closest('.form-group')
					.find('.glyphicon')
					.remove();
				$(element).after( $('<span />', { class: 'glyphicon glyphicon-remove form-control-feedback'}) );
			}
		},
		unhighlight : function (element, errorClass, validClass) {
			if (element.type == 'checkbox' || element.type == 'radio') {
				this.findByName(element.name).removeClass(errorClass).addClass(validClass);
			} else {
				$(element).closest('.form-group')
					.removeClass('has-error has-feedback')
					.addClass('has-success has-feedback');
				$(element).closest('.form-group')
					.find('.glyphicon')
					.remove();
				$(element).after( $('<span />', { class: 'glyphicon glyphicon-ok form-control-feedback'}) )
			}
		},
		errorPlacement: function (error, element) {
			if (element.parent('.input-group').length || element.prop('type') === 'checkbox' || element.prop('type') === 'radio') {
				error.insertAfter(element.parent());
			} else {
				error.insertAfter(element);
			}
		}
	});
	// End - Set Default Validation Script.

	// Hamburger Menu - Scripts
	$(document).on('click', '.menu-hamburger .menu-open', function() {
		$('.menu-content').animate({
			'right' : 0,
		})
		$(this).fadeOut();
		$('.menu-hamburger .menu-close').fadeIn();
	})
	$(document).on('click', '.menu-hamburger .menu-close', function() {
		$('.menu-content').animate({
			'right' : '-60%',
		})
		$(this).fadeOut();
		$('.menu-hamburger .menu-open').fadeIn();
	})
	$(document).scroll(function(){
		if ( $(this).scrollTop() >= 50 )
			$('.menu-hamburger').addClass('float')
		else
			$('.menu-hamburger').removeClass('float')
	})
	// End - Hamburger Menu - Scripts

	// Click ho table row to tik checkbox inside.
	$('.tr-checkbox').on('click', function(e) {
		if ($(e.target).hasClass('tr-checkbox') || $(e.target).is('td')) {

			$(this).find('input[type=checkbox]').prop('checked', function(){
				return !this.checked
			})

		}
	})
	$('table th').on('change', '[type=checkbox].checkbox-all', function() {
		var table = $(this).closest('table');
		table.find('tbody [type=checkbox]').prop('checked', $(this).prop('checked'))
	})
	// End - Click ho table row to tik checkbox inside.

	// Set readonly to true or false when radio with etc input.
	$('.radio-with-etc input[type=radio]').change(function() {
		var box = $(this).closest('.radio-with-etc')
		var cur_val = $(this).val()
		var last_val = box.data('last-val')
		var target = box.data('etc-target')

		if (cur_val == last_val)
			$(target).attr('readonly', false)
		else
			$(target).val('').attr('readonly', true)
	})

	// Insert '-' when each fields has no contant.
	$('.col-sm-8, td').each(function() {
		if (isEmpty($(this)))
			$(this).text('-')
	})

	// Confirm before remove anything.
	$('.destroy_btn').click(function() {
		if (confirm("คุณแน่ใจที่จะลบข้อมูลนี้?")) {
			var target = $(this).data('destroy');
			console.log( target );
			console.log( $(target) );
			$(target).submit();
		}
	})
})

var loadingScreen = $('#loading');

$(window).load(function() {
	resize()
})

function autoTextAreaSize() {
    var textarea = $('textarea');
    autosize.destroy(textarea);
    autosize(textarea);
}

$.notifyDefaults({
    type : 'success',
    placement : {
        from: "top",
        align : 'center'
    },
    offset: 50,
    z_index: 1100
});
